$(function() {

	if($('.m-block').length){
		$('.m-block').click(function() {
			var current_block = $(this).attr('id');
			$('.m-block').removeClass('active');
			$(this).addClass('active');
			$('.m-block-details').hide();
			$('.m-block-details.' + current_block).fadeIn( 500 );	
		});
	}

	if($('#sliderRealisations').length){
		$('#sliderRealisations').bxSlider({
			slideMargin: 30,
			auto: true,
			autoReload: false,
			controls: false,
			breaks: [{screen:0, slides:1},{screen:460, slides:1},{screen:768, slides:3}],
			onSliderLoad: function(){
				var parentHeight = $('ul#sliderRealisations li').height();
				$('.realisation-overlay').height(parentHeight - 20);	
			}
	  });
	}	

	$('.map-coords').click(function() {
		var name = $(this).attr("id");
		$('.map-details').hide();
		$('.map-details.' + name).fadeIn(200);
		$(this).siblings('.area').removeClass('active');
		$(this).siblings('.' + name).addClass('active');
	});

	$( window ).resize(function() {
		var parentHeight = $('ul#sliderRealisations li').height();
		$('.realisation-overlay').height(parentHeight - 20);
	});

	$('a[href*=\\#]:not([href=\\#])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		  var target = $(this.hash);
		  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		  if (target.length) {
		    $('html,body').animate({
		      scrollTop: target.offset().top
		    }, 1000);
		    return false;
		  }
		}
	});	

	// if($(".alphabet").length){
	// 	$( window ).resize(function() {
	// 		var wrap = $(".alphabet");
	// 		var position = wrap.position();		
	// 		wrap.removeClass("f-nav");
	// 	    $(window).scroll(function () {
	// 	        if ($(this).scrollTop() > position.top) {
	// 	            wrap.addClass("f-nav");
	// 	        } else {
	// 	            wrap.removeClass("f-nav");
	// 	        }
	// 	    });		
	// 	});
	// }

	// if($(".alphabet").length){
	// 	var wrap = $(".alphabet");
	// 	var position = wrap.position(); 

	//   $(window).scroll(function () {   	
	//       if ($(this).scrollTop() > position.top) {
	//           wrap.addClass("f-nav");
	//       } else {
	//           wrap.removeClass("f-nav");
	//       }
	//   });
	// }

	$('.agence').click(function() {
		var current_block = $(this).attr('id');
		$('.agence').removeClass('active');
		$(this).addClass('active');
		$('.references-agence').hide();
		$('.references-agence.' + current_block).fadeIn( 500 );	
	});  

});